import { css } from '@emotion/react'
import {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { CardElevation,FileUpload } from '@findep/mf-landings-core'
import {withThemeProps} from '@findep/microfronts-core'
import { Box, Typography, Grid, Button, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import PhoneIcon from '@material-ui/icons/Phone';

import { VerificacionId } from '../../../services/LandingV4/verificacionCredencial.service'
import { navigate } from "../../../helpers/queryNavigate"
import { iconStyle, h1Style, root } from '../../../styles/lo-sentimos'


const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const VerificacionCredencial = (props) => {

    const {
        pageContext,
        titleIconText,
        descriptionText,
        fileUploadINEFront,
        helperText,
        fileUploadINEBack,
        textButton,
        awaitText,
        alertText,
    } = props

    const [generarError, setGeneralError] = useState(true);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [errorService, setErrorService] = useState(false)
    const [flujo, setFlujo] = useState('');
    const [state, setState] = useState({frontPicture:'', backPicture: ''});

    const classes = useStyles();

    useEffect(() => {
        let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        
        setFlujo( flujo )
    }, [])

    useEffect(() => {
        state.frontPicture && state.backPicture ?
        setGeneralError(false) :
        setGeneralError(true)
    },[state])

    const root = css`
        padding: 1rem;
    `

    const styleTitle = css`
        margin-bottom: 1rem;
    `

    const text = css`
        padding: 1rem;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    `

    const btnContainer = css`
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
    `

    const rootBtn = css`
        border-radius: 20px;
    `

    const handleFile = async (file, flag) => {
        console.log(file.value);
        await setState({...state, [flag]: file.value})
    }

    const handleNextStep = async () => {
        setOpenBackDrop(true)
        const verificacion = new VerificacionId(flujo.creditApplicationId )
        verificacion.setPictures(state.frontPicture, state.backPicture)
        try {
            const { status,data } = await verificacion.send()
            if (status === 200 || status === 204) {
                sessionStorage.setItem('flujo',JSON.stringify({...flujo, tubo:{...flujo.tubo,VerifyIdentityWithAutonomation: 'InProgress'}}))
                navigate(pageContext?.next?.path)
            }
        } catch (error) {
            setOpenBackDrop(false)
            setErrorService(true)
            //El envio al server fallo
            console.error(error)
        }
    }
    return (
        <Grid container justify="center" >
            <Grid item xs={12} md={6} css={root}  >
                <Grid container css={styleTitle} justify="center">
                    <div css={root}>
                        <PhoneIcon css={iconStyle} />
                        <Typography css={h1Style} component='span'>
                            {titleIconText}
                        </Typography>      
                    </div>
                    {/* <TitleIcon title={titleIconText} icon={PhoneIcon} textColor={true} /> */}
                </Grid >
                <CardElevation>
                    <Box>
                        <Typography css={text}>
                            {descriptionText}
                        </Typography>
                        <Box css={ styleTitle }>
                        <FileUpload
                            id="verificacion-credencial-FrenteDeTuINE-input"
                            name="verificacion-credencial-FrenteDeTuINE-input"
                            accept=".png, .jpeg"
                            icon={ContactMailIcon}
                            help='ayuda'
                            label={fileUploadINEFront}
                            helperText={helperText}
                            onChange={ e => handleFile(e, 'frontPicture')}
                            required                            
                        />
                        </Box>
                        <FileUpload
                            id="verificacion-credencial-ReversoDeTuINE-input"
                            name="verificacion-credencial-ReversoDeTuINE-input"
                            accept=".png, .jpeg"
                            icon={CreditCardIcon}
                            help='ayuda'
                            label={fileUploadINEBack}
                            helperText={helperText}
                            onChange={ e => handleFile(e, 'backPicture')}
                            required
                        />
                    </Box>
                    <Box css={btnContainer}>
                        <Button
                        id="verificacion-credencial-nextStep-button"
                          variant="contained"
                          color="primary"
                          startIcon={<TrendingFlatIcon />}
                          css={rootBtn}
                          disabled={generarError}
                          onClick={handleNextStep}
                        >
                          {textButton}
                        </Button>
                    </Box>
                    <Typography css={text}>
                        {awaitText}
                    </Typography>
                </CardElevation>
                
            </Grid>
            <Backdrop open={openBackDrop} className={classes.backdrop}>
              <CircularProgress color="primary" />
            </Backdrop>
            <Snackbar open={errorService} autoHideDuration={6000} onClose={() => setErrorService(false)}>
                        <Alert variant="filled" severity="warning" onClose={() => setErrorService(false)}>
                            {alertText}
                        </Alert>
                    </Snackbar>
        </Grid>
    );
}

VerificacionCredencial.defaultProps = {
    titleIconText : 'Verificación de identidad vía telefónica',
    descriptionText : 'A continuación te pediremos adjuntar una imagen de tu identificación oficial.',
    fileUploadINEFront : 'Frente de tu identificación',
    helperText : 'Puedes subir archivos .jpeg o .png',
    fileUploadINEBack : 'Reverso de tu  identificación',
    textButton : 'Continuar',
    awaitText : 'En este momento estamos revisando la información que nos proporcionaste, uno de nuestros agentes se pondrá en contacto contigo en breve para más detalles',
    alertText : 'Lo sentimos, por el momento el servicio no se encuentra disponible'
  }

export default withThemeProps(VerificacionCredencial, 'VDNPVerificacionIdentidad');
